/* General styles */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  overflow: auto;
}

.App {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.7;
}

.overlay {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(206, 227, 230, 0.5);
  color: black;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 1000;
  font-size: 1.7rem;
  font-weight: bold;
}

.resume-button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.resume-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.resume-button:active {
  background-color: #004494;
}

.main-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 100px;
  overflow: hidden;
}

.main-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.profile-card, .card {
  background-color: rgba(172, 213, 230, 0.8);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.profile-card, .card {
  width: 100%;
  height: 350px;
  flex-shrink: 1;
}

.about-me-card {
  grid-column: span 2;
  height: auto; /* Ensure height adjusts to content */
}

.about-me-card p {
  font-size: 1.2rem;
  margin: 5px 0;
  line-height: 1.2;
  padding: 20px 20px 0px 20px;
}

.about-me-card .education {
  margin-top: 5px;
}

.about-me-card .education h3 {
  margin-bottom: 5px;
}

.about-me-card .education p {
  font-size: 1.1rem;
  margin-bottom: 10px;
  padding: 0px 20px 0px 20px;
}

.project-card {
  grid-column: span 2;
  height: auto; /* Ensure the card expands to fit all content */
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for desktop */
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 50px;
}

.project-icon {
  position: relative;
  width: 200px; /* Set a fixed width for desktop */
  height: 100px; /* Adjust height to maintain aspect ratio */
  margin: 0 auto; /* Center the icons */
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-icon img {
  width: 200px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px; /* Add rounded corners to the image */
}

.project-icon:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.work-experience-card {
  grid-row: span 2;
  height: auto; /* Ensure height adjusts to content */
}

.experience-timeline {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  overflow-y: auto;
}

.experience-box {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden; /* Hide overflow initially */
  height: 100px; /* Initial height */
  z-index: 1;
}

.experience-box:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 28%; /* Expand to full height */
  z-index: 10; /* Bring to front */
  position: relative;
}

.experience-box h3 {
  margin: 0;
  font-size: 1.3rem;
  color: #333;
}

.experience-box p {
  margin: 5px 0;
  font-size: 1rem;
  color: #555;
}

.experience-box .description {
  display: none;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #555;
}

.experience-box:hover .description {
  display: block;
}

.profile-card .profile-pic {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 10px;
  margin-top: 20px;
}

.profile-card h2, .card h2 {
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  color: black;
}

.profile-card h3, .profile-card h4, .card h3 {
  margin: 0;
  padding: 5px 0;
  font-size: 1.2rem;
  color: black;
  text-align: center; /* Center text under the profile picture */
}

.profile-card h4 {
  text-align: center;
}

.card-header {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px 10px 0 0;
  padding: 10px 20px;
  box-sizing: border-box;
  text-align: left;
}

.skills-card, .contact-card {
  grid-column: span 1;
}

.skills-card {
  position: relative;
  overflow: hidden;
  transition: height 0.3s ease;
}

.skills-card:hover {
  height: 130%;
  overflow: visible;
}

.skills-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  column-count: 2;
}

.skills-list li {
  width: 50%;
  padding: 5px 0;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-boxes {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 80%;
  margin-top: 30px;
  gap: 20px;
  align-items: center;
}

.contact-box {
  flex: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  width: 50px;
}

.contact-box a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.contact-box:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-box img.contact-icon {
  width: 60px;
  height: 60px;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  /* Adjust navbar and button */
  .navbar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem; /* Reduce font size */
  }

  .resume-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  /* Vertical layout for all boxes */
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px; /* Reduce gap to maximize screen usage */
    padding: 10px; /* Reduce padding to fit more content */
  }

  .profile-card, .card, .work-experience-card, .skills-card, .contact-card {
    width: 95%; /* Use more width of the screen */
    margin: 5px 0; /* Reduce margin to maximize space */
    box-sizing: border-box;
  }

  /* Display projects in a single column */
  .projects-grid {
    display: flex;
    flex-direction: column; /* Stack projects vertically */
    gap: 15px; /* Adjust gap between project icons */
    width: 100%; /* Use full width */
    box-sizing: border-box;
  }

  .project-icon {
    width: 90%; /* Reduce icon width */
    height: auto; /* Allow height to adjust with content */
    position: relative;
    overflow: hidden; /* Ensure visibility */
    padding: 5px; /* Add padding around each project icon */
    border-radius: 10px; /* Add rounded corners */
    margin: 0 auto; /* Center the icons */
  }

  .project-icon img {
    position: static; /* Reset position to default */
    width: 100%; /* Full width for the images */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Add rounded corners to the image */
  }

  .project-icon:last-child {
    width: 40%; /* Set consistent width */
    align-self: center; /* Center the last project icon */
  }

  /* Remove hover effects and keep skills expanded */
  .skills-card {
    height: auto;
  }

  .skills-list {
    column-count: 1; /* Single column layout */
    width: 100%;
  }

  .experience-box, .skills-card {
    transform: none;
    box-shadow: none;
    cursor: default;
  }

  .experience-box:hover, .skills-card:hover {
    transform: none;
    box-shadow: none;
  }
}
